const PrivacyPolicy = () => {
  return (
    <div className="space-y-6">
      <div className="p-4 md:p-6 text-center">
        <h1 className="font-bold text-4xl md:text-5xl text-rocPurple-800 font-prompt">{`Privacy Policy`}</h1>
      </div>
      <div className="rounded-2xl bg-rocWhite-300 p-4 space-y-4 font-manrope">
        <h2 className="font-bold text-2xl text-rocPurple-800">1. Introduction</h2>
        <p>Welcome to Realty on Chain (ROC), operated by Web3 Corporation LTD (Blockera), a company registered in London, United Kingdom ("Realty on Chain" or "we"). This Privacy Policy ("Policy") explains how we collect, use, and disclose your personal information when you access and use our platform, including the websites located at www.rocplatform.online (the "Platform").</p>
        <h2 className="font-bold text-2xl text-rocPurple-800">2. Information We Collect</h2>
        <br></br>
        <strong>When you access and use the Platform, we may collect the following information from you:</strong>
        <br></br>
        <br></br>
        <span>
          <p><strong>Personal Information:</strong> This includes information that can be used to identify you directly, such as your name, email address, phone number, and date of birth.</p>
          <br></br>
          <p><strong>Account Information:</strong> This includes information you provide when you register for an account, such as your username and password.</p>
          <br></br>
          <p><strong>Transaction Information:</strong> This includes information about your transactions on the Platform, such as the assets you invest in and the amount you invest.</p>
          <br></br>
          <p><strong>Usage Data:</strong> This includes information that can be used to identify you directly, such as your name, email address, phone number, and date of birth.</p>
          <br></br>
          <p><strong>Device Information:</strong> This includes information about how you use the Platform, such as the pages you visit, the features you use, and the time you spend on the Platform.</p>
        </span>
        <br></br>
        <span>
          <h2 className="font-bold text-2xl text-rocPurple-800">3. How We Use Your Information</h2>
          <br></br>
          <p>
            <strong>We may use your information for the following purposes:</strong>
            <ul className="list-disc pl-4">
              <br></br>
              <li>To provide and operate the Platform and deliver the services you request.</li>
              <li>To verify your identity and ensure the security of the Platform.</li>
              <li>To communicate with you about your account, transactions, and other matters related to the Platform.</li>
              <li>To personalize your experience on the Platform.</li>
              <li>To improve the Platform and develop new features and services.</li>
              <li>To comply with legal and regulatory requirements.</li>
            </ul>
          </p>
        </span>
        <br></br>
        <span>
          <h2 className="font-bold text-2xl text-rocPurple-800">4. Sharing Your Information</h2>
          <p>
          <br></br>
          <strong>We may share your information with the following third parties:</strong>
          <ul className="list-disc pl-4">
            <br></br>
            <li>Service providers who help us operate the Platform, such as cloud storage providers and payment processors.</li>
            <li>Regulatory authorities and law enforcement agencies, as required by law.</li>
            <li>Third parties with your consent, such as other users on the Platform when you engage in certain features.</li>
          </ul>
          </p>
        </span>
        <br></br>
        <span>
          <h2 className="font-bold text-2xl text-rocPurple-800">5. Data Security</h2>
          <br></br>
          <p>We take reasonable precautions to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure, and we cannot guarantee the security of your information.</p>
        </span>
        <br></br>
        <span>
          <h2 className="font-bold text-2xl text-rocPurple-800">6. Data Retention</h2>
          <br></br>
          <p>We will retain your information for as long as necessary to fulfill the purposes described in this Policy, unless a longer retention period is required or permitted by law.</p>
        </span>
        <br></br>
        <span>
          <h2 className="font-bold text-2xl text-rocPurple-800">7. Your Choices</h2>
          <br></br>
          <p>
            <strong>You have certain choices regarding your information:</strong>
            <ul className="list-disc pl-4">
              <br></br>
              <li>You can access and update your information in your account settings.</li>
              <li>You can opt out of receiving marketing communications from us.</li>
              <li>To communicate with you about your account, transactions, and other matters related to the Platform.</li>
              <li>To personalize your experience on the Platform.</li>
              <li>To improve the Platform and develop new features and services.</li>
              <li>You can request to delete your account.</li>
            </ul>
          </p>
        </span>
        <br></br>
        <span>
          <h2 className="font-bold text-2xl text-rocPurple-800">8. Changes to This Policy</h2>
          <br></br>
          <p>
          We may update this Policy from time to time. We will notify you of any changes by posting the new Policy on the Platform.
          </p>
        </span>
        <br></br>
        <span>
        <h2 className="font-bold text-2xl text-rocPurple-800">9. Contact Us</h2>
          <br></br>
          <span>If you have any questions about this Policy, please contact us at "info@blockera.online"</span>
          <br></br>
          <br></br>
        </span>
        
      </div>
    </div>
  );
};

export default PrivacyPolicy;
